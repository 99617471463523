import inMemoryJWT from "../utils/inMemoryJwt";
import jwt_decode from "jwt-decode";

export const authProvider = {
  login: async ({ username, password }) => {
    const { REACT_APP_API_LOGIN, REACT_APP_API_PASSWORD, REACT_APP_BACKEND_URL } = process.env

    if (username !== 'boss' || password !== 'sekret') {
        return Promise.reject();
    }
    const request = new Request(`${REACT_APP_BACKEND_URL}/auth/token/`, {
      method: 'POST',
      body: JSON.stringify({ email: REACT_APP_API_LOGIN, password: REACT_APP_API_PASSWORD }),
      headers: new Headers({ 'Content-Type': 'application/json' }),      
    });
    inMemoryJWT.setRefreshTokenEndpoint(`${REACT_APP_BACKEND_URL}/auth/token/refresh/`);
    try {
      const response = await fetch(request);
      if (response.status < 200 || response.status >= 300) {
        throw new Error(response.statusText);
      }
      const auth = await response.json();
      const decoded = jwt_decode(auth?.access)
      inMemoryJWT.setToken(auth?.access, decoded?.exp * 1000);
    } catch (e) {
      console.log("error", e);
      throw new Error('Network error');
    }
    },
  logout: () => {
    inMemoryJWT.eraseToken();
    return Promise.resolve();
  },
  checkAuth: () => {
    return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
  },
  checkError: (error) => {
    const status = error.status;
    if (status === 401 || status === 403) {
        inMemoryJWT.eraseToken();
        return Promise.reject();
    }
    // other error code (404, 500, etc): no need to log out
    return Promise.resolve();
  },
  getIdentity: () => {
    return Promise.resolve({
      id: 'user',
      fullName: 'Uzytkownik',
    });
  },
  getPermissions: () => {
    return inMemoryJWT.getToken() ? Promise.resolve() : Promise.reject();
  },
};