import * as React from "react";
import { ThemeProvider } from '@mui/material/styles';

import { Admin, Resource, CustomRoutes } from 'react-admin';
import DRFProvider from './providers/DRFProvider';
import { authProvider } from './providers/authProvider';
import { ClientProfileList } from './components/clientProfiles/ClientProfileList';
import { ClientProfileShow } from './components/clientProfiles/ClientProfileShow';
import { Dashboard } from './components/common/Dashboard';
import { CustomMenu } from './components/common/CustomMenu';
import { Charts } from './components/Charts';
import { VisitList } from './components/visits/VisitList';
import { VisitShow } from './components/visits/VisitShow';
import { WorkshopList } from './components/workshops/WorkshopList';
import { WorkshopShow } from './components/workshops/WorkshopShow';
import { WorkshopProfileList } from './components/workshopProfiles/WorkshopProfileList';
import { Route } from "react-router-dom";

const dataProvider = DRFProvider();

const App = () => (
  <ThemeProvider>
    <Admin dashboard={Dashboard} dataProvider={dataProvider} authProvider={authProvider} menu={CustomMenu} requireAuth>
      <Resource name="workshops" options={{label: 'Warsztaty'}} list={WorkshopList} show={WorkshopShow} />
      <Resource name="client_profiles" options={{label: 'Profile klientów'}} list={ClientProfileList} show={ClientProfileShow} />
      <Resource name="workshop_profiles" options={{label: 'Profile warsztatów'}} list={WorkshopProfileList} />
      <Resource name="visits" options={{label: 'Wizyty'}} list={VisitList} show={VisitShow} />
      <CustomRoutes>
        <Route path="/chart" element={<Charts />} />
      </CustomRoutes>
    </Admin>
  </ThemeProvider>
);

export default App;