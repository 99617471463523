import React from "react";
import { Button, Card, Container, CardContent, Grid } from '@mui/material';
import { Title } from 'react-admin';


export const Dashboard = () => {
  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Card>
            <Title title="Panel MeldMoto" />
            <CardContent>
              <h1>Dashboard</h1>
              <p>Witaj w panelu MeldMoto</p>
              <Button variant="contained" href="/#/workshops">Lista warsztatów</Button>
              <p></p>
              <Button variant="contained" href="/#/visits">Lista wizyt</Button>
              <p></p>
              <Button variant="contained" href="/#/client_profiles">Lista profili klientów</Button>
              <p></p>
              <Button variant="contained" href="/#/workshop_profile">Lista profili warsztatów</Button>
              <p></p>
              <Button variant="contained" color="success" href="/#/chart">Postępy</Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  )
};