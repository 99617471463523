import { Show, EmailField, NumberField, ImageField, TextField, useAuthenticated } from 'react-admin';

export const ClientProfileShow = () => {
  useAuthenticated();
  return (
    <Show>
      <EmailField label="EMail" source="email" emptyText="-" />
      <TextField label="Imię" source="name" emptyText="-" />
      <TextField label="Nazwisko" source="surname" emptyText="-" />
      <NumberField label="Numer" source="street_number" emptyText="-" />
      <TextField label="Telefon" source="phone" emptyText="-" />
      <TextField label="O sobie" source="bio" emptyText="-" />
      <ImageField label="Zdjęcie" source="image" />
    </Show>
  )
};
