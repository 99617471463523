import React from "react";
import moment from "moment";
import { Card, CardContent } from '@mui/material';

export const CustomTooltip = ({ active, payload }) => {
  const [date, cumulativeValue] = payload;
  if (active) {
    return (
      <Card>
        <CardContent>
          <p>Ilość: {cumulativeValue.value}</p>
          <p>Data: {moment(date.value).format("DD-MM-YY")}</p>
        </CardContent>
      </Card>
    );
  }
  return null;
};
