import { Container } from '@mui/material';
import { Datagrid, List, BooleanField, NumberField, EmailField, TextField, FunctionField, useAuthenticated } from 'react-admin';

export const ClientProfileList = () => {
  useAuthenticated();
  return (
    <Container>
      <List sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
          <NumberField source="id" />
          <EmailField label="Email" source="email" emptyText="-" />
          <TextField label="Imię" source="name" emptyText="-" />
          <TextField label="Nazwisko" source="surname" emptyText="-" />
          <TextField label="Telefon" source="phone" emptyText="-" />
          <FunctionField label="Opis" render={record => record.bio?.substr(0, 100)} emptyText="-" />
          <BooleanField label="Aktywowane konto" source="is_active" />
        </Datagrid>
      </List>
    </Container>
  );
};