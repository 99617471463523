/* eslint-disable import/no-anonymous-default-export */
import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';
import inMemoryJWT from '../utils/inMemoryJwt';

const { REACT_APP_BACKEND_URL } = process.env

const apiUrl = REACT_APP_BACKEND_URL;

export default () => {
  const httpClient = (url) => {
    const options = {
        headers: new Headers({ Accept: 'application/json', 'Content-Type': 'application/json' }),
    };
    const token = inMemoryJWT.getToken();
    if (token) {
        options.headers.set('Authorization', `Bearer ${token}`);
        return fetchUtils.fetchJson(url, options);
    } else {
      inMemoryJWT.setRefreshTokenEndpoint(`${REACT_APP_BACKEND_URL}/auth/token/refresh/`);
      return inMemoryJWT.getRefreshedToken().then((gotFreshToken) => {
        if (gotFreshToken) {
          options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
        };
        return fetchUtils.fetchJson(url, options);
      });
    }
  };

  return {
    getList: async (resource, params) => {
      let resourceUrl = resource;
      if (resource === 'visits') {
        resourceUrl = 'visits/boss_list';
      }
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify(params.filter),
          randomize: false,
      };
      const url = `${apiUrl}/${resourceUrl}?${stringify(query)}`;
      const { json } = await httpClient(url);
      if (resource === 'visits') {
        return ({
          data: json ? json?.map((record) => {
            return {
              id: record?.id, ...record,
              is_quick_admission: record?.initiated_by === 'INIT_QUICK_ADMISSION',
              car: record?.quick_admission ? `${record?.quick_admission?.make_name} ${record?.quick_admission?.model_name}` : `${record?.car?.make_name} ${record?.car?.model_name}`,
              cart_value_fee: record?.service_cart && (Math.round((record?.service_cart?.total_price_with_fee - record?.service_cart?.total_price) * 100) / 100),
              cart_value_without_fee: record?.service_cart && record?.service_cart?.total_price,
              cart_value_with_fee: record?.service_cart && record?.service_cart?.total_price_with_fee,
            };
          }) : [],
          total: json?.length,
        });
      }

      return ({
        // data: json['results'],
        data: json ? json?.map((record) => {
          return { id: record?.id, ...record, hasImage: record?.workshop_images?.length > 0 ?? false };
        }) : [],
        // total: parseInt(headers.get('content-range').split('/').pop(), 10),
        total: json?.length,
      });
    },

    getOne: async (resource, params) => {
      const { json } = await httpClient(`${apiUrl}/${resource}/${params.id}/`);
      if (resource === 'visits') {
        return ({
          data: {
            id: json?.id, ...json,
            is_quick_admission: json?.initiated_by === 'INIT_QUICK_ADMISSION',
            car: json?.quick_admission ? `${json.quick_admission.make_name} ${json.quick_admission.model_name}` : `${json.car.make_name} ${json.car.model_name}`,
            cart_value_fee: json?.service_cart && (Math.round((json.service_cart.total_price_with_fee - json.service_cart.total_price) * 100) / 100),
            cart_value_without_fee: json?.service_cart && json.service_cart.total_price,
            cart_value_with_fee: json?.service_cart && json.service_cart.total_price_with_fee,
          }
        });
      }

      return ({
        // filter out null and undefined items and use uuid for id
        data: { ...json, imageUrl: json?.workshop_images[0]?.image ?? null }, // Object.entries({ id: json.id, ...json }).reduce((a,[k,v]) => (v == null ? a : (a[k]=v, a)), {})
      });
    },

    getMany: async (resource, params) => {
      const query = {
          filter: JSON.stringify({ ids: params.ids }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;
      const { json } = await httpClient(url);
      return ({ data: json });
    },

    getManyReference: async (resource, params) => {
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const query = {
          sort: JSON.stringify([field, order]),
          range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
          filter: JSON.stringify({
              ...params.filter,
              [params.target]: params.id,
          }),
      };
      const url = `${apiUrl}/${resource}?${stringify(query)}`;

      const { headers, json } = await httpClient(url);
      return ({
        data: json,
        total: parseInt(headers.get('content-range').split('/').pop(), 10),
      });
    },

    create: (resource, params) => { return console.log("CREATE OPERATION BLOCKED", resource, params)},
        // httpClient(`${apiUrl}/${resource}`, {
        //     method: 'POST',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({
        //     data: { ...params.data, id: json.id },
        // })),

    update: (resource, params) => { return console.log("UPDATE OPERATION BLOCKED", resource, params)},
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({ data: json })),

    updateMany: (resource, params) => {
      return console.log("UPDATE OPERATION BLOCKED", resource, params)
        // const query = {
        //     filter: JSON.stringify({ id: params.ids}),
        // };
        // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //     method: 'PUT',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({ data: json }));
    },

    delete: (resource, params) => { return console.log("DELETE OPERATION BLOCKED", resource, params)},
        // httpClient(`${apiUrl}/${resource}/${params.id}`, {
        //     method: 'DELETE',
        // }).then(({ json }) => ({ data: json })),

    deleteMany: (resource, params) => {
      return console.log("DELETE OPERATION BLOCKED", resource, params);
        // const query = {
        //     filter: JSON.stringify({ id: params.ids}),
        // };
        // return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
        //     method: 'DELETE',
        //     body: JSON.stringify(params.data),
        // }).then(({ json }) => ({ data: json }));
    },
  }
};