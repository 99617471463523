import { Show, ArrayField, Datagrid, BooleanField, ChipField, NumberField, FunctionField, TextField, TabbedShowLayout, Tab, useAuthenticated } from 'react-admin';

export const VisitShow = () => {
  useAuthenticated();
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Podstawowe informacje">
          <NumberField source="id" />
          <FunctionField label="Termin" render={record => record?.scheduled_time ? new Date(record?.scheduled_time).toLocaleString() : '-'} emptyText='-' />
          <FunctionField label="Termin zakończenia" render={record => record?.end_time ? new Date(record?.end_time).toLocaleString() : '-'} emptyText='-' />
          <TextField label="Warsztat" source="workshop.name" emptyText="-" />
          <TextField label="Miasto" source="workshop.city" emptyText="-" />
          <TextField label="Ulica" source="workshop.street" emptyText="-" />
          <ChipField label="Status" source="status" emptyText="-" />
          <ChipField label="Akceptacja klienta" source="service_scope_status" emptyText="-" />
          <BooleanField label="Szybka" source="is_quick_admission" />
          <TextField label="Samochód" source="car" emptyText="-" />
          <TextField label="Wartość prowizji" source="cart_value_fee" emptyText="-" />
          <TextField label="Wartość koszyka" source="cart_value_without_fee" emptyText="-" />
          <TextField label="Wartość koszyka z prowizją" source="cart_value_with_fee" emptyText="-" />
        </Tab>
        <Tab label="Warsztat">
          <TextField label="Warsztat" source="workshop.name" emptyText="-" />
          <TextField label="Miasto" source="workshop.city" emptyText="-" />
          <TextField label="Ulica" source="workshop.street" emptyText="-" />
          <TextField label="Numer" source="workshop.street_number" emptyText="-" />
          <TextField label="Kod pocztowy" source="workshop.postcode" emptyText="-" />
          <TextField label="Numer NIP" source="workshop.tax_id" emptyText="-" />
          <FunctionField label="Mapa" render={record => `https://maps.google.com/?q=${record.workshop.latitude},${record.workshop.longitude}`}/>
          <FunctionField label="Strona warsztatu" render={record => `https://meldmoto.pl/warsztat/${record.workshop.slug}`}/>
        </Tab>
        <Tab label="Koszyk">
          <ArrayField label="Lista przedmiotów" reference="service_cart.items" source="service_cart.items">
            <Datagrid>
              <TextField label="Nazwa" source="item.name" />
              <NumberField label="Ilość" source="quantity" />
              <NumberField label="Cena" source="item.price" />
              <NumberField label="Cena z prowizją" source="item.price_with_fee" />
              <NumberField label="Cena razem" source="item_price" />
              <NumberField label="Cena razem z prowizją" source="item_price_with_fee" />
              <BooleanField label="Czy zaakceptowany" source="is_accepted" />
            </Datagrid>
          </ArrayField>
          <NumberField label="Wartość prowizji" source="cart_value_fee" emptyText="-" />
          <NumberField label="Wartość koszyka" source="cart_value_without_fee" emptyText="-" />
          <NumberField label="Wartość koszyka z prowizją" source="cart_value_with_fee" emptyText="-" />
        </Tab>
        <Tab label="Lista życzeń">
          <ArrayField label="Lista przedmiotów" reference="wish_list.items" source="wish_list.items">
            <Datagrid>
              <TextField label="Nazwa" source="item.name" />
              <NumberField label="Ilość" source="quantity" />
              <NumberField label="Cena" source="item.price" />
              <NumberField label="Cena z prowizją" source="item.price_with_fee" />
              <NumberField label="Cena razem" source="item_price" />
              <NumberField label="Cena razem z prowizją" source="item_price_with_fee" />
              <BooleanField label="Czy zaakceptowany" source="is_accepted" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
};
