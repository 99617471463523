import { Show, EmailField, ArrayField, Datagrid, SingleFieldList, BooleanField, ChipField, NumberField, FunctionField, ImageField, TextField, TabbedShowLayout, Tab, useAuthenticated } from 'react-admin';

const WEEK_DAYS = {
  0: 'Poniedziałek',
  1: 'Wtorek',
  2: 'Środa',
  3: 'Czwartek',
  4: 'Piątek',
  5: 'Sobota',
  6: 'Niedziela',
};

export const WorkshopShow = () => {
  useAuthenticated();
  return (
    <Show>
      <TabbedShowLayout>
        <Tab label="Warsztat">
          <TextField source="id" />
          <TextField label="Nazwa" source="name" emptyText="-" />
          <TextField label="Miasto" source="city" emptyText="-" />
          <TextField label="Ulica" source="street" emptyText="-" />
          <NumberField label="Numer" source="street_number" emptyText="-" />
          <TextField label="Kod pocztowy" source="postcode" emptyText="-" />
          <TextField label="Numer NIP" source="tax_id" emptyText="-" />
          <TextField label="Opis" source="description" emptyText="-" />
          <ImageField label="Zdjęcie" source="imageUrl" />
          <FunctionField label="Mapa" render={record => `https://maps.google.com/?q=${record.latitude},${record.longitude}`}/>
          <FunctionField label="Strona warsztatu" render={record => `https://meldmoto.pl/warsztat/${record.slug}`}/>
          <ArrayField label="Kategorie" reference="typ" source="typ">
            <SingleFieldList linkType="show">
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
        </Tab>
        <Tab label="Kontakt">
          <TextField label="Telefon 1" source="workshop_contact.contact_phone" emptyText="-" />
          <TextField label="Telefon 2" source="workshop_contact.contact_phone2" emptyText="-" />
          <EmailField label="Email" source="workshop_contact.contact_email" emptyText="-" />
          <EmailField label="Email 2" source="workshop_contact.contact_email2" emptyText="-" />
        </Tab>
        <Tab label="Pracownicy">
          <ArrayField label="Lista pracowników" reference="employees" source="employees">
            <Datagrid>
              <TextField label="Adres email" source="profile.email" />
              <BooleanField label="Właściciel" source="is_owner" />
              <BooleanField label="Aktywny" source="active" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Godziny otwarcia">
          <ArrayField label="Godziny otwarcia" reference="workshop_opening_hours" source="workshop_opening_hours">
            <Datagrid>
              <FunctionField label="Dzień tygodnia" render={record => WEEK_DAYS[record.day_of_week]}/>
              <TextField label="Od" source="opens_at" />
              <TextField label="Do" source="closes_at" />
              <BooleanField label="Zamknięte" source="closed" />
              <BooleanField label="Otwarte 24h" source="opened_24h" />
              <ChipField label="Kategoria" source="workshop_typ.name" />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
};
