import React from "react";
import { Card, Container, CardContent, Grid, Typography } from '@mui/material';
import { useGetList, Loading, useAuthenticated } from 'react-admin';
import { CartesianGrid, XAxis, YAxis,Tooltip, Legend, ScatterChart, Scatter, ResponsiveContainer, ReferenceLine, Line } from 'recharts';
import moment from "moment";
import { CustomTooltip } from './common/CustomTooltip';

export const Charts = () => {
  useAuthenticated();
  const { data, isLoading, error } =  useGetList('workshops', {sort: { field: 'created_at', order: 'ASC' }});
  const { data: data2, isLoading: isLoading2, error: error2 } = useGetList('client_profiles', {sort: { field: 'created_at', order: 'ASC' }});
  const { data: data3, isLoading: isLoading3, error: error3 } = useGetList('workshop_profiles', {sort: { field: 'created_at', order: 'ASC' }});
  const { data: data4, isLoading: isLoading4, error: error4 } = useGetList('visits', {sort: { field: 'created_at', order: 'ASC' }});

  const subtractDays = (date, days) => {
    date.setDate(date.getDate() - days);
    return date;
  };

  const getTicks = (initialDate, futureDate) => {
    const ticks = [];
    const firstDate = initialDate;
    let tempDate = futureDate;
    while (tempDate >= firstDate) {
      ticks.push(tempDate.getTime());
      tempDate = subtractDays(tempDate, 7);
    }
    return ticks.reverse();
  };

  const convertResults = (items) => {
    let cumulativeResult = [];
    const newResults = items.reduce((acc, item) => ({
      ...acc,
      [item.created_at.split('T')[0]]: (acc[item.created_at.split('T')[0]] || 0) + 1
    }) , {})

    const finalResult = Object.keys(newResults).map((key) => {
      return {date: new Date(key).getTime(), value: newResults[key]}
    });
    finalResult.sort((a, b) => Number(a.date) - Number(b.date));

    cumulativeResult = finalResult.reduce((acc, { value, ...item }, index) => {
      acc.push({
        ...item,
        cumulativeValue: value + (acc[index - 1]?.cumulativeValue ?? 0)
      });
      return acc;
    }, []);
    return cumulativeResult;
  };

  const renderChart = (results, axisLabel) => {
    const delta = results.reduce(function (acc, element, index, array) {
       index && acc.push(element['cumulativeValue'] - array[index - 1]['cumulativeValue']);
      return acc;
    }, []);    
    const avgDiff = delta.reduce(function (a, b) { return a + b; }) / delta.length;

    const diffTime = Math.abs(results[results.length - 1].date - results[0].date);
    const diffDays = Math.ceil(diffTime / (1000 * 3600 * 24));
    const trueDayDiff = avgDiff / results.length / diffDays;

    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + 8 * 7);
    const futureResults = [...results];
    const lastResult = results[results.length - 1];
    let currentDate = lastResult?.date;
    let currentValue = lastResult?.cumulativeValue;
    while (futureDate.getTime() >= currentDate) {
      currentDate = currentDate + 1000 * 3600 * 24;
      currentValue = currentValue + trueDayDiff;
      futureResults.push({date: currentDate, cumulativeValue: currentValue});
    }
    return (
      <ResponsiveContainer height={500}>
        <ScatterChart>
          <XAxis
            dataKey="date"
            scale="time"
            domain={["auto", futureDate.getTime()]}
            name="Time"
            tickFormatter={(unixTime) => moment(unixTime).format("DD-MM")}
            type="number"
            ticks={getTicks(results[0]?.date, futureDate)}
            interval={0}
            angle={-45}
            tickMargin={-10}
            dy={20}
            style={{fontSize: '0.5rem',}}
          />
          <YAxis dataKey="cumulativeValue" name="Value" />
          <Scatter
            data={results}
            line={{ stroke: "#f4c714" }}
            lineType="joint"
            lineJointType="monotoneX"
            name={axisLabel}
          />
          <Scatter
            data={results}
            line={{ stroke: "#999999" }}
            lineType="fitting"
            lineJointType="monotoneX"
            legendType="none"
            shape={() => {}}
          />
          <Scatter
            data={futureResults}
            line={{ stroke: "#dddddd" }}
            lineType="fitting"
            lineJointType="monotoneX"
            legendType="none"
            shape={() => {}}
          />
          <Legend />
          <Tooltip content={<CustomTooltip />} />
          <CartesianGrid strokeDasharray="3 3" />
          <ReferenceLine x={new Date().getTime()} stroke="green" label="Dziś" />
        </ScatterChart>
      </ResponsiveContainer>
    )
  }

  const renderWorkshopsChart = (data, isLoading, error) => {
    let workshopResults = [];
    if (data?.length > 0) {
      workshopResults = convertResults(data);
    }

    if (isLoading) { return <Loading />; }
    if (error) { return <p>Problem z załadowaniem danych</p>; }

    return (
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <h2>Pozyskiwanie warsztatów</h2>
            {renderChart(workshopResults, "Liczba warsztatów")}
          </CardContent>
        </Card>
      </Grid>
    )
  };

  const renderVisitsChart = (data, isLoading, error) => {
    let visitResults = [];
    if (data?.length > 0) {
      visitResults = convertResults(data);
    }

    if (isLoading) { return <Loading />; }
    if (error) { return <p>Problem z załadowaniem danych</p>; }

    return (
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <h2>Nowe wizyty</h2>
            {renderChart(visitResults, "Liczba wizty")}
          </CardContent>
        </Card>
      </Grid>
    )
  };

  const renderClientProfilesChart = (data, isLoading, error) => {
    let clientProfileResults = [];
    if (data?.length > 0) {
      clientProfileResults = convertResults(data);
    }

    if (isLoading) { return <Loading />; }
    if (error) { return <p>Problem z załadowaniem danych</p>; }

    return (
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <h2>Pozyskiwanie profili klientów</h2>
            {renderChart(clientProfileResults, "Liczba profili klientów")}
          </CardContent>
        </Card>
      </Grid>
    )
  };

  const renderWorkshopProfilesChart = (data, isLoading, error) => {
    let workshopProfileResults = [];
    if (data?.length > 0) {
      workshopProfileResults = convertResults(data);
    }

    if (isLoading) { return <Loading />; }
    if (error) { return <p>Problem z załadowaniem danych</p>; }

    return (
      <Grid item md={6} xs={12}>
        <Card>
          <CardContent>
            <h2>Pozyskiwanie profili warsztatów</h2>
            {renderChart(workshopProfileResults, "Liczba profili warsztatów")}
          </CardContent>
        </Card>
      </Grid>
    )
  };

  return (
    <Container>
      <Grid container spacing={2}>
      <Grid item xs={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" align="center">
              {`Warsztaty ${data?.length ?? 0}`}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" align="center">
              {`Wizyty ${data4?.length ?? 0}`}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" align="center">
              {`Profile klientów ${data2?.length ?? 0}`}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <Card>
          <CardContent>
            <Typography variant="h6" align="center">
              {`Profile warsztatów ${data3?.length ?? 0}`}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
        {renderWorkshopsChart(data, isLoading, error)}
        {renderVisitsChart(data4, isLoading4, error4)}
        {renderClientProfilesChart(data2, isLoading2, error2)}
        {renderWorkshopProfilesChart(data3, isLoading3, error3)}
      </Grid>
    </Container>
  )
};