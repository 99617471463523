import { Container } from '@mui/material';
import { Datagrid, List, BooleanField, NumberField, TextInput, TextField, FunctionField, useAuthenticated } from 'react-admin';

export const WorkshopList = () => {
  useAuthenticated();
  const workshopFilters = [
    <TextInput label="Search" source="name" alwaysOn />,
  ];

  return (
    <Container>
      <List filters={workshopFilters} sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
          <NumberField source="id" />
          <TextField label="Nazwa" source="name" emptyText="-" />
          <TextField label="Miasto" source="city" emptyText="-" />
          <TextField label="Ulica" source="street" emptyText="-" />
          <NumberField label="Numer" source="street_number" emptyText="-" />
          <TextField label="Kod pocztowy" source="postcode" emptyText="-" />
          <TextField label="Numer NIP" source="tax_id" emptyText="-" />
          <FunctionField label="Opis" render={record => record.description?.substr(0, 100)} emptyText="-" />
          <BooleanField label="Zdjęcie" source="hasImage" />
        </Datagrid>
      </List>
    </Container>
  );
};