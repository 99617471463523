import { Menu } from 'react-admin';

import ListIcon from '@mui/icons-material/List';
import InsertChartIcon from '@mui/icons-material/InsertChart';
import PersonIcon from '@mui/icons-material/Person';
import EngineeringIcon from '@mui/icons-material/Engineering'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

export const CustomMenu = () => (
    <Menu>
        <Menu.DashboardItem />
        <Menu.Item to="/workshops" primaryText="Warsztaty" leftIcon={<ListIcon />}/>
        <Menu.Item to="/client_profiles" primaryText="Profile klientów" leftIcon={<PersonIcon />}/>
        <Menu.Item to="/workshop_profiles" primaryText="Profile warsztatów" leftIcon={<EngineeringIcon />}/>
        <Menu.Item to="/visits" primaryText="Wizyty" leftIcon={<CalendarMonthIcon />}/>
        <Menu.Item to="/chart" primaryText="Postępy" leftIcon={<InsertChartIcon />}/>
    </Menu>
);