import jwt_decode from "jwt-decode";

const inMemoryJWTManager = () => {
  let inMemoryJWT = null;
  let isRefreshing = null;
  let logoutEventName = 'ra-logout';
  let refreshEndpoint = '/auth/token/refresh/';
  let refreshTimeOutId;

  const setLogoutEventName = name => logoutEventName = name;
  const setRefreshTokenEndpoint = endpoint => refreshEndpoint = endpoint;


  // This listener allows to disconnect another session of react-admin started in another tab
  window.addEventListener('storage', (event) => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  // This countdown feature is used to renew the JWT in a way that is transparent to the user.
  // before it's no longer valid
  const refreshToken = (delay) => {
    refreshTimeOutId = window.setTimeout(
      getRefreshedToken,
      delay * 1000 - 5000
    ); // Validity period of the token in seconds, minus 5 seconds
  };

  const abortRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  // The method makes a call to the refresh-token endpoint
  // If there is a valid cookie, the endpoint will return a fresh jwt.
  const getRefreshedToken = () => {
    const request = new Request(refreshEndpoint, {
      method: 'POST',
      headers: new Headers({ 'Content-Type': 'application/json' }),
    });
    isRefreshing = fetch(request)
      .then((response) => {
        if (response.status !== 200) {
          eraseToken();
          global.console.log('Failed to renew the jwt from the refresh token.');
          return { token: null };
        }
        return response.json();
      })
      .then((token) => {
        const { access } = token;
        const decoded = jwt_decode(access)
        if (token) {
          setToken(access, decoded?.exp * 1000);
          return true;
        }
        return false;
      });
    return isRefreshing;
  };

  const getToken = () => inMemoryJWT;

  const setToken = (token, delay) => {
    inMemoryJWT = token;
    refreshToken(delay);
    return true;
  }

  const eraseToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    window.localStorage.setItem(logoutEventName, Date.now());
    return true;
  };

  return {
    eraseToken,
    getToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
  }
}

export default inMemoryJWTManager();