import { Container } from '@mui/material';
import { Datagrid, ChipField, List, BooleanField, NumberField, TextField, FunctionField, useAuthenticated } from 'react-admin';

export const VisitList = () => {
  useAuthenticated();

  return (
    <Container>
      <List sort={{ field: 'id', order: 'DESC' }}>
        <Datagrid rowClick="show">
          <NumberField source="id" />
          <FunctionField label="Termin" render={record => record?.scheduled_time ? new Date(record?.scheduled_time).toLocaleString() : '-'} emptyText='-' />
          <TextField label="Warsztat" source="workshop.name" emptyText="-" />
          <TextField label="Miasto" source="workshop.city" emptyText="-" />
          <TextField label="Ulica" source="workshop.street" emptyText="-" />
          <ChipField label="Status" source="status" emptyText="-" />
          <BooleanField label="Szybka" source="is_quick_admission" />
          <TextField label="Samochód" source="car" emptyText="-" />
          <TextField label="Wartość prowizji" source="cart_value_fee" emptyText="-" />
          <TextField label="Wartość koszyka" source="cart_value_without_fee" emptyText="-" />
          <TextField label="Wartość koszyka z prowizją" source="cart_value_with_fee" emptyText="-" />
        </Datagrid>
      </List>
    </Container>
  );
};